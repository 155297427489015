import React from 'react';
import { connect } from 'react-redux';

import Loading from '../UI/Loading.js';
import CrosswordListItem from './CrosswordListItem.js';
import {
  updateCrosswordStatus,
  deleteCrossword,
} from '../../redux/actions/crosswords.js';
import CardSkeleton from '../UI/CardSkeleton.js';

const CrosswordList = (props) => {
  const skeletons = [1, 2, 3];
  const handleDeleteCrossword = (crosswordId) => {
    props.deleteCrossword(crosswordId);
  };

  const handleStatusUpdate = (crosswordId, status) => {
    props.updateCrosswordStatus({ crosswordId, status });
  };

  return props.error ? (
    <div>{props.errorDetail}</div>
  ) : props.loading ? (
    // <div className="quiz-list__content">
    //   {skeletons.map((sk) => (
    //     <CardSkeleton key={sk} kreeda={{ status: 'draft' }} />
    //   ))}
    // </div>
    <Loading />
  ) : props.crosswords.length < 1 ? (
    <div
      style={{
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <p style={{ textAlign: 'center', color: 'grey' }}>
        Wow, such emptiness. Why don't you create a new crossword. :)
      </p>
    </div>
  ) : (
    <div className="quiz-list__content">
      {props.crosswords.map((crossword) => (
        <CrosswordListItem
          key={crossword.crosswordId}
          crossword={crossword}
          onDelete={handleDeleteCrossword}
          onStatusUpdate={handleStatusUpdate}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  crosswords: state.crosswords.crosswords,
  loading: state.crosswords.loading,
  error: state.crosswords.error,
  errorDetail: state.crosswords.errorDetail,
});

const mapDispatchToProps = {
  updateCrosswordStatus,
  deleteCrossword,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrosswordList);
