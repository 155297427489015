import React from 'react';

const CardSkeleton = ({ kreeda }) => {
  return (
    <div className="card-item">
      <div className="card-item__head">
        <div className="card-item__head__heading">
          <div className="h3__skeleton"></div>
          <div className="text__skeleton"></div>
          <div className="text__skeleton"></div>
        </div>
      </div>
      <div className="card-item__actions">
        <button className="btn__skeleton--primary"></button>
        <button
          className="btn__skeleton--secondary"
          disabled={kreeda.status === 'draft'}
        ></button>
      </div>
    </div>
  );
};

export default CardSkeleton;
