import React from 'react';

import imgQuiz from '../../assets/images/quiz.png';
import imgCrossword from '../../assets/images/crossword.png';

const SelectType = ({ currentStep, setKreeda, setCurrentStep }) => {
  return (
    <>
      <h3>Select Type</h3>
      <div className="type-items">
        <div
          className="type-item"
          onClick={() => {
            setKreeda((kreeda) => ({
              ...kreeda,
              type: 'quiz',
            }));
            setCurrentStep(currentStep + 1);
          }}
        >
          <div className="type-item__heading">
            <h4>QUIZ</h4>
          </div>
          <div className="type-item__image">
            <img src={imgQuiz} />
          </div>
        </div>
        <div
          className="type-item"
          onClick={() => {
            setKreeda((kreeda) => ({
              ...kreeda,
              type: 'crossword',
            }));
            setCurrentStep(currentStep + 1);
          }}
        >
          <div className="type-item__heading">
            <h4>CROSSWORD</h4>
          </div>
          <div className="type-item__image">
            <img src={imgCrossword} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectType;
