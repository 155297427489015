import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Loading from '../../components/UI/Loading.js';
import {
  getCrossword,
  getSubmissions,
} from '../../redux/actions/crosswords.js';

const ListSubmissions = (props) => {
  const params = useParams();
  const crosswordId = params.id;

  const crossword = props.crosswords.find(
    (crossword) => crossword.crosswordId === crosswordId
  );

  useEffect(() => {
    if (!crossword) {
      props.getCrossword(crosswordId);
      props.getSubmissions(crosswordId);
    }
  }, []);

  if (props.loading) return <Loading />;

  return (
    <div className="submissions-container">
      {crossword &&
      crossword.submissions &&
      crossword.submissions.length > 0 ? (
        <>
          <div className="submission-column--name">
            <div className="submission--header">
              <p>Name</p>
            </div>
            {crossword.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.name}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--email">
            <div className="submission--header">
              <p>Email</p>
            </div>
            {crossword.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.email}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--score">
            <div className="submission--header">
              <p>Score</p>
            </div>
            {crossword.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.score}</p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p>No Submissions so far...</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    crosswords: state.crosswords.crosswords,
    loading: state.crosswords.loading,
    error: state.crosswords.error,
  };
};

const mapDispatchToProps = {
  getCrossword,
  getSubmissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSubmissions);
