import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCrosswords } from '../../redux/actions/crosswords.js';
import { clearError } from '../../redux/reducers/crosswords.js';
import CrosswordList from '../../components/Crossword/CrosswordList.js';

const ListCrosswords = (props) => {
  useEffect(() => {
    if (props.crosswords.length <= 1) props.getCrosswords();
    return () => {
      props.clearError();
    };
  }, []);
  return <CrosswordList />;
};

const mapStateToProps = (state) => ({
  crosswords: state.crosswords.crosswords,
  loading: state.crosswords.loading,
  error: state.crosswords.error,
  errorDetail: state.crosswords.errorDetail,
});

const mapDispatchToProps = {
  getCrosswords,
  clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCrosswords);
