import { createAsyncThunk } from '@reduxjs/toolkit';

import crosswordService from '../../services/crossword.service.js';

export const getCrosswords = createAsyncThunk(
  'crosswords/get',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const crosswords = await crosswordService.getCrosswords();
      return fulfillWithValue({ crosswords: [...crosswords] });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createCrossword = createAsyncThunk(
  'crosswords/create',
  async (crossword, { fulfillWithValue, rejectWithValue }) => {
    try {
      const createdCrossword =
        await crosswordService.createCrossword(crossword);
      return fulfillWithValue({ crossword: { ...createdCrossword } });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCrossword = createAsyncThunk(
  'crosswords/getOne',
  async (crosswordId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const crossword = await crosswordService.getCrosswordDetails(crosswordId);
      return fulfillWithValue({ crossword });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCrossword = createAsyncThunk(
  'crosswords/update',
  async ({ crosswordId, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const crossword = await crosswordService.updateCrossword(
        crosswordId,
        data
      );
      return fulfillWithValue(crossword);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCrossword = createAsyncThunk(
  'crosswords/delete',
  async (crosswordId, { fulfillWithValue, rejectWithValue }) => {
    try {
      await crosswordService.deleteCrossword(crosswordId);
      return fulfillWithValue({ crosswordId });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCrosswordStatus = createAsyncThunk(
  'crosswords/publish',
  async ({ crosswordId, status }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const crossword = await crosswordService.updateCrosswordStatus(
        crosswordId,
        status
      );
      return fulfillWithValue(crossword);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateWords = createAsyncThunk(
  'crosswords/updateWords',
  async ({ crosswordId, words }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await crosswordService.updateWords(crosswordId, words);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSubmissions = createAsyncThunk(
  'crosswords/getSubmissions',
  async (crosswordId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const submissions = await crosswordService.getSubmissions(crosswordId);
      return fulfillWithValue({ crosswordId, submissions });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
