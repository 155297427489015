import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const QuestionItem = ({ question, quizStatus, onEdit, onDelete }) => {
  return (
    <div className="question-item__container">
      <div className="question-item__content">
        <div className="question-item__text">
          <h4>Question: {question.text}</h4>
        </div>
        <div className="question-item__options">
          <div className="question-item__option">
            <h5>A: {question.optionA}</h5>
            <FontAwesomeIcon
              icon={question.answer === 'A' ? faCircleCheck : faCircle}
            />
          </div>
          <div className="question-item__option">
            <h5>B: {question.optionB}</h5>
            <FontAwesomeIcon
              icon={question.answer === 'B' ? faCircleCheck : faCircle}
            />
          </div>
          <div className="question-item__option">
            <h5>C: {question.optionC}</h5>
            <FontAwesomeIcon
              icon={question.answer === 'C' ? faCircleCheck : faCircle}
            />
          </div>
          <div className="question-item__option">
            <h5>D: {question.optionD}</h5>
            <FontAwesomeIcon
              icon={question.answer === 'D' ? faCircleCheck : faCircle}
            />
          </div>
        </div>
      </div>
      <div className="question-item__controls">
        <button
          className="btn--primary"
          onClick={() => {
            onEdit(question);
          }}
          disabled={quizStatus === 'published'}
        >
          EDIT
        </button>
        <button
          className="btn--secondary"
          onClick={() => {
            onDelete(question);
          }}
          disabled={quizStatus === 'published'}
        >
          DELETE
        </button>
      </div>
    </div>
  );
};

export default QuestionItem;
