import React, { useRef, useState } from 'react';

const Modal = ({ id, component: Component, componentProps }) => {
  const ref = useRef();
  const [closing, setClosing] = useState(false);

  const closeModal = (e) => {
    const modal = document.getElementById(id);
    if (e) {
      if (modal && !ref.current?.contains(e.target)) {
        if (modal.style.display === 'flex') {
          setClosing(true);
        }
        modal.style.display = 'none';
      }
    }
  };

  document.addEventListener('mousedown', closeModal);

  return (
    <div id={id} className="modal-container">
      <div ref={ref} className="modal-content">
        <Component
          {...componentProps}
          closing={closing}
          setClosing={setClosing}
        />
      </div>
    </div>
  );
};

export default Modal;
