import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Loading from '../../components/UI/Loading.js';
import QuizForm from '../../components/Quiz/QuizForm.js';
import QuestionItem from '../../components/Question/QuestionItem.js';
import QuestionForm from '../../components/Question/QuestionForm.js';
import {
  getQuizzes,
  getQuiz,
  updateQuiz,
  updateQuestion,
  addQuestion,
  removeQuestion,
  deleteQuiz,
} from '../../redux/actions/quizzes.js';
import { setEditingQuestionId } from '../../redux/reducers/quizzes.js';
import QuestionItemSkeleton from '../../components/UI/QuestionItemSkeleton.js';

const EditQuiz = (props) => {
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const quizId = params.id;
  let quiz = props.quizzes.find((quiz) => quiz && quiz.quizId === quizId);

  useEffect(() => {
    if (!quiz || !quiz.questions) props.getQuiz(quizId);

    return () => {
      props.setEditingQuestionId({ questionId: '' });
    };
  }, []);

  const emptyQuestion = {
    text: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    answer: 'A',
  };

  const handleQuizUpdate = (quiz) => {
    props.updateQuiz({
      quizId: quiz.quizId,
      data: { name: quiz.name, duration: quiz.duration },
    });
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  const handleQuizDelete = () => {
    props.deleteQuiz(quiz.quizId);
    navigate('/quizzes');
  };

  const handleEditingQuestionItem = (question) => {
    props.setEditingQuestionId({ questionId: question.questionId });
  };

  const handleCancelEditingQuestionItem = () => {
    props.setEditingQuestionId({ questionId: null });
  };

  const handleQuestionItemUpdate = (question) => {
    props.updateQuestion({ quizId: quiz.quizId, question });
  };

  const handleAddQuestionItem = (question) => {
    props.addQuestion({ quizId: quiz.quizId, question });
  };

  const handleRemoveWordItem = (question) => {
    props.removeQuestion({
      quizId: quiz.quizId,
      questionId: question.questionId,
    });
  };

  if (props.loading) return <Loading />;

  return (
    quiz && (
      <div className="quiz__container">
        {isEditing ? (
          <div className="quiz__header">
            <QuizForm
              quiz={quiz}
              onSubmit={handleQuizUpdate}
              onCancel={handleEditCancel}
            />
          </div>
        ) : (
          <div className="quiz__header">
            <div className="quiz__header__heading">
              <h3>{quiz.name}</h3>
              <p>{quiz.duration / 60} min.</p>
            </div>
            <div className="quiz__header__actions">
              <button
                className="btn--primary"
                onClick={() => {
                  setIsEditing(true);
                }}
                disabled={quiz.status === 'published'}
              >
                EDIT
              </button>
              <button
                className="btn--secondary"
                onClick={handleQuizDelete}
                disabled={quiz.status === 'published'}
              >
                DELETE
              </button>
            </div>
          </div>
        )}
        {quiz.status === 'draft' && (
          <button
            className="btn--primary"
            onClick={(e) => {
              const form = document.getElementById('create');
              if (isAddQuestionOpen) {
                form.style.display = 'none';
              } else {
                form.style.display = 'flex';
              }
              setIsAddQuestionOpen(!isAddQuestionOpen);
            }}
          >
            {isAddQuestionOpen ? 'CANCEL' : 'ADD QUESTION'}
          </button>
        )}

        <QuestionForm
          action="create"
          question={emptyQuestion}
          onAdd={handleAddQuestionItem}
        />

        {quiz.questions && quiz.questions.length > 0 && (
          <div className="quiz__content">
            {quiz.questions.map((question) =>
              props.editingQuestionId === question.questionId ? (
                question.loading ? (
                  <QuestionItemSkeleton key={question.questionId} />
                ) : (
                  <QuestionForm
                    action="update"
                    key={question.questionId}
                    question={question}
                    onAdd={handleQuestionItemUpdate}
                    onCancel={handleCancelEditingQuestionItem}
                  />
                )
              ) : (
                <QuestionItem
                  key={question.questionId}
                  question={question}
                  quizStatus={quiz.status}
                  onEdit={handleEditingQuestionItem}
                  onDelete={handleRemoveWordItem}
                />
              )
            )}
          </div>
        )}
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    quizzes: state.quizzes.quizzes,
    loading: state.quizzes.loading,
    error: state.quizzes.error,
    errorDetail: state.quizzes.errorDetail,
    editingQuestionId: state.quizzes.editingQuestionId,
  };
};

const mapDispatchToProps = {
  getQuizzes,
  getQuiz,
  updateQuiz,
  addQuestion,
  updateQuestion,
  removeQuestion,
  setEditingQuestionId,
  deleteQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQuiz);
