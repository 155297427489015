import React from 'react';

const WordItem = ({ word }) => {
  return (
    <div className="word-item__content">
      <div className="word-item__answer">
        <h5>{word.answer}</h5>
      </div>
      <div className="word-item__clue">
        <h5>{word.clue}</h5>
      </div>
    </div>
  );
};

export default WordItem;
