import React from 'react';

const Loading = () => {
  return (
    <div className="loader-container">
      <ul>
        <li>
          <div className="loader">
            <div className="child"></div>
          </div>
        </li>
        <li>
          <div className="text"></div>
        </li>
      </ul>
    </div>
  );
};

export default Loading;
