import React, { useEffect, useState } from 'react';

import SelectType from './SelectType.js';
import AddDetails from './AddDetails.js';

function CreateKreeda({ closing, setClosing }) {
  // const [value, setValue] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [kreeda, setKreeda] = useState({
    type: '',
    details: {
      name: '',
      duration: '',
    },
    settings: {},
  });

  const steps = [
    <SelectType
      currentStep={currentStep}
      setKreeda={setKreeda}
      setCurrentStep={setCurrentStep}
    />,
    <AddDetails
      type={kreeda.type}
      setKreeda={setKreeda}
      setCurrentStep={setCurrentStep}
    />,
    // 'Settings',
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    // const getHash = async () => {
    //   const encoder = new TextEncoder();
    //   const data = encoder.encode('SEGMENTATION');
    //   const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    //   const hashArray = Array.from(new Uint8Array(hashBuffer));
    //   const hashHex = hashArray
    //     .map((b) => b.toString(16).padStart(2, '0'))
    //     .join('');
    //   setValue(hashHex);
    // };
    // getHash();
    if (closing === true) {
      setCurrentStep(0);
      setKreeda({
        type: '',
        details: {
          name: '',
          duration: '',
        },
        settings: {},
      });
      setClosing(false);
    }
  }, [closing]);

  return (
    <>
      <div className="create-container">
        <div className="create-content">
          {/* <div className="create-type">Select Type</div>
          <div className="create-details">Enter Details</div>
          <div className="create-settings">Provide Settings</div> */}
          {steps.map((step, index) => (
            <div
              key={index}
              className="create-step"
              style={{ transform: `translateX(-${currentStep * 100}%)` }}
            >
              {step}
            </div>
          ))}
        </div>
        {/* <div className="create-navigation">
          <button onClick={handleNext} disabled={kreeda.type === ''}>
            {currentStep == steps.length - 1 ? 'FINISH' : 'NEXT'}
          </button>
        </div> */}
      </div>
    </>
  );
}

export default CreateKreeda;
