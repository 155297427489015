import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

import {
  createCrossword,
  getCrosswords,
  updateCrossword,
  updateWords,
  deleteCrossword,
  updateCrosswordStatus,
  getCrossword,
  getSubmissions,
} from '../actions/crosswords.js';

const crosswordsSlice = createSlice({
  name: 'crosswords',
  initialState: {
    crosswords: [],
    loading: false,
    error: false,
    errorDetail: '',
    lastCreatedCrosswordId: null,
    editingWordId: null,
  },
  reducers: {
    setlastCreatedCrosswordId: (state, action) => {
      state.lastCreatedCrosswordId = action.payload.crosswordId || null;
    },
    clearError: (state, _action) => {
      state.error = false;
      state.errorDetail = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCrosswords.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCrosswords.fulfilled, (state, action) => {
        state.crosswords = [...action.payload.crosswords];
        state.loading = false;
        state.error = false;
      })
      .addCase(getCrosswords.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(createCrossword.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createCrossword.fulfilled, (state, action) => {
        const {
          crosswordId = v4(),
          name = '',
          duration,
          status,
          wordsCount = 0,
          words = [],
        } = action.payload.crossword;
        const newCrossword = {
          crosswordId,
          name,
          duration,
          status,
          words,
          wordsCount,
        };
        state.crosswords = [...state.crosswords, newCrossword];
        state.loading = false;
        state.error = false;
        state.lastCreatedCrosswordId = newCrossword.crosswordId;
      })
      .addCase(createCrossword.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(updateCrossword.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateCrossword.fulfilled, (state, action) => {
        const { crosswordId, name, duration } = action.payload.crossword;
        const crossword = state.crosswords.find(
          (crossword) => crossword.crosswordId === crosswordId
        );
        crossword.name = name;
        crossword.duration = duration;
        state.loading = false;
        state.error = false;
      })
      .addCase(updateCrossword.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(getCrossword.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCrossword.fulfilled, (state, action) => {
        const { crosswordId, words } = action.payload.crossword;
        const crossword = state.crosswords.find(
          (crossword) => crossword.crosswordId === crosswordId
        );
        if (crossword) {
          crossword.words = words;
        } else {
          state.crosswords.push(action.payload.crossword);
        }
        state.loading = false;
        state.error = false;
      })
      .addCase(getCrossword.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(updateCrosswordStatus.pending, (state, action) => {
        const { crosswordId } = action.meta.arg;
        const crossword = state.crosswords.find(
          (crossword) => crossword.crosswordId === crosswordId
        );
        crossword.loading = true;
        crossword.error = false;
      })
      .addCase(updateCrosswordStatus.fulfilled, (state, action) => {
        const { crosswordId, status, code } = action.payload.crossword;
        const crossword = state.crosswords.find(
          (crossword) => crossword.crosswordId === crosswordId
        );
        crossword.status = status;
        crossword.code = code;
        crossword.loading = false;
        crossword.error = false;
      })
      .addCase(updateCrosswordStatus.rejected, (state, action) => {
        const { crosswordId } = action.meta.arg;
        const crossword = state.crosswords.find(
          (crossword) => crossword.crosswordId === crosswordId
        );
        crossword.loading = false;
        crossword.error = true;
        crossword.errorDetail = action.payload;
      })
      .addCase(deleteCrossword.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteCrossword.fulfilled, (state, action) => {
        const { crosswordId } = action.payload;
        state.crosswords = state.crosswords.filter(
          (crossword) => crossword.crosswordId !== crosswordId
        );
        state.loading = false;
        state.error = false;
      })
      .addCase(deleteCrossword.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(updateWords.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateWords.fulfilled, (state, action) => {
        const { words, crosswordId } = action.payload;
        const crossword = state.crosswords.find(
          (crossword) => crossword.crosswordId === crosswordId
        );
        crossword.words = words;
        crossword.wordsCount = words.length;
        state.loading = false;
        state.error = false;
      })
      .addCase(updateWords.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      })
      .addCase(getSubmissions.pending, (state, _action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSubmissions.fulfilled, (state, action) => {
        const { crosswordId, submissions } = action.payload;
        const crossword = state.crosswords.find(
          (crossword) => crossword.crosswordId === crosswordId
        );
        crossword.submissions = submissions;
        state.loading = false;
        state.error = false;
      })
      .addCase(getSubmissions.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorDetail = action.payload;
      });
  },
});

export const { setlastCreatedCrosswordId, clearError } =
  crosswordsSlice.actions;

export default crosswordsSlice;
