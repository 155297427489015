import { configureStore } from '@reduxjs/toolkit';

import quizzesSlice from '../reducers/quizzes.js';
import crosswordsSlice from '../reducers/crosswords.js';

export default () => {
  const store = configureStore({
    reducer: {
      quizzes: quizzesSlice.reducer,
      crosswords: crosswordsSlice.reducer,
    },
  });
  return store;
};
