import React, { useState } from 'react';
import { canBuildCrossword } from '../../utils/crossword.js';

const WordBox = (props) => {
  const [validationMessage, setValidationMessage] = useState('');
  const [text, setText] = useState(
    props.words
      ? props.words.map((word) => `${word.answer}: ${word.clue}`).join('\n')
      : ''
  );

  const handleTextChange = (e) => {
    setValidationMessage('');
    const regex = /^[a-zA-Z0-9:,.\s\n]*$/;
    if (regex.test(e.target.value)) {
      setText(e.target.value);
    } else {
      setValidationMessage(
        'can only use alphabets, numbers, and following special characters: `:` `,` `.`'
      );
    }
  };

  const handleWordsSubmit = () => {
    const regex = /^(([a-zA-Z\s]*:[a-zA-Z0-9\s.,]*(?:\n+|$))+)?$/;

    if (regex.test(text)) {
      const answerList = [];
      const wordList = [];
      const lines = text.trim().replace(/\n+/g, '\n');
      lines.split('\n').forEach((line) => {
        line = line.trim().replace(/\n+/g, '\n');
        const word = line.split(':');
        answerList.push(word[0]);
        wordList.push({
          answer: word[0].trim().replace(/\s+/g, ''),
          clue: word[1].trim().replace(/\s+/g, ' '),
        });
      });

      if (canBuildCrossword(answerList)) {
        props.onUpdate(wordList);
      } else {
        setValidationMessage('The given set of words cannot form a crossword!');
      }
    } else {
      setValidationMessage('Please enter data in proper format');
    }
  };

  return (
    <div className="word-box__container">
      <div className="word-box__content">
        <p className="validation-message">{validationMessage}</p>
        <textarea
          className="word-box__textarea"
          value={text}
          onChange={handleTextChange}
          rows={10}
          onFocus={() => setValidationMessage('')}
        ></textarea>
        <br />
      </div>
      <div className="word-box__controls">
        <button className="btn--primary" onClick={handleWordsSubmit}>
          SUBMIT
        </button>
        <button className="btn--secondary" onClick={props.onCancel}>
          CANCEL
        </button>
      </div>
    </div>
  );
};

export default WordBox;
