import React from 'react';
import { useState } from 'react';

const CrosswordForm = (props) => {
  const [validationMessage, setValidationMessage] = useState('');
  const [crossword, setCrossword] = useState(
    props.crossword || {
      name: '',
      duration: '600',
    }
  );

  const handleNameChange = (e) => {
    if (e.target.value.length <= 35) {
      setCrossword({
        ...crossword,
        name: e.target.value,
      });
    } else {
      e.target.value = e.target.value.slice(0, 35);
    }
  };

  const handleDurationChange = (e) => {
    setCrossword({
      ...crossword,
      duration: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (crossword.name === '') {
      setValidationMessage('Crossword Name cannot be empty');
    } else {
      props.onSubmit(crossword);
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__control">
        <div>
          <input
            type="text"
            name="name"
            placeholder="Name"
            defaultValue={crossword.name}
            onChange={handleNameChange}
            onFocus={() => {
              setValidationMessage('');
            }}
          />
          <label className="character-limit">
            {crossword.name.length} / 35
          </label>
        </div>
        <div>
          Duration:{' '}
          {crossword.duration < 600
            ? `0${crossword.duration / 60}`
            : crossword.duration / 60}{' '}
          min
          <input
            type="range"
            min={'300'}
            max={'3600'}
            step={300}
            defaultValue={crossword.duration}
            onChange={handleDurationChange}
          />
        </div>
      </div>
      <div className="form__actions">
        <button className="btn--primary" type="submit">
          {props.crossword ? 'UPDATE' : 'CREATE'}
        </button>
        <button className="btn--secondary" onClick={props.onCancel}>
          CANCEL
        </button>
      </div>
      <p className="validation-message">{validationMessage}</p>
    </form>
  );
};

export default CrosswordForm;
