import React from 'react';
import { connect } from 'react-redux';

import Loading from '../UI/Loading.js';
import QuizListItem from './QuizListItem.js';
import { updateQuizStatus, deleteQuiz } from '../../redux/actions/quizzes.js';
import CardSkeleton from '../UI/CardSkeleton.js';

const QuizList = (props) => {
  const skeletons = [1, 2, 3];
  const handleDeleteQuiz = (quizId) => {
    props.deleteQuiz(quizId);
  };

  const handleStatusUpdate = (quizId, status) => {
    props.updateQuizStatus({ quizId, status });
  };

  return props.error ? (
    <div>{props.errorDetail}</div>
  ) : props.loading ? (
    // <div className="quiz-list__content">
    //   {skeletons.map((sk) => (
    //     <CardSkeleton key={sk} kreeda={{ status: 'draft' }} />
    //   ))}
    // </div>
    <Loading />
  ) : props.quizzes.length < 1 ? (
    <div
      style={{
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <p style={{ textAlign: 'center', color: 'grey' }}>
        Wow, such emptiness. Why don't you create a new quiz. :)
      </p>
    </div>
  ) : (
    <div className="quiz-list__content">
      {props.quizzes.map((quiz) => (
        <QuizListItem
          key={quiz.quizId}
          quiz={quiz}
          onDelete={handleDeleteQuiz}
          onStatusUpdate={handleStatusUpdate}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  quizzes: state.quizzes.quizzes,
  loading: state.quizzes.loading,
  error: state.quizzes.error,
  errorDetail: state.quizzes.errorDetail,
});

const mapDispatchToProps = {
  updateQuizStatus,
  deleteQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
