import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import AppRouter from './routers/AppRouter.js';
import './styles/main.scss';
import createStore from './redux/store/initialize.js';
import { getQuizzes } from './redux/actions/quizzes.js';
import securityService from './services/security.service.js';
import favIcon from './assets/images/favicon.ico';

const appRoot = document.createElement('div');
appRoot.id = 'root';
document.body.appendChild(appRoot);
const root = ReactDOM.createRoot(appRoot);

const store = createStore();

const authPromise = window.location.href.includes('/login/callback')
  ? securityService.completeUserAuth()
  : securityService.checkUserSession();

authPromise
  .then((auth) => {
    const favIconLink = document.createElement('link');
    favIconLink.rel = 'icon';
    favIconLink.type = 'image/ico';
    favIconLink.href = favIcon;
    document.head.appendChild(favIconLink);
    if (auth.status === 'Authenticated') {
      // store.dispatch(getQuizzes()).then(() => {
      root.render(
        <Provider store={store}>
          <AppRouter auth={auth} />
        </Provider>
      );
      // });
    } else {
      return securityService.inititateUserAuth();
    }
  })
  .then((loginUrl) => {
    if (loginUrl) window.location.href = loginUrl;
  })
  .catch((error) => {
    console.log(error);
  });
