export function canBuildCrossword(words) {
  if (words.length < 2) return false;

  // Helper function to find overlapping characters
  function findOverlap(word1, word2) {
    for (let i = 0; i < word1.length; i++) {
      for (let j = 0; j < word2.length; j++) {
        if (word1[i] === word2[j]) {
          return true;
        }
      }
    }
    return false;
  }

  // Create a graph representation of word connections
  const graph = {};
  words.forEach((word) => {
    graph[word] = [];
  });

  // Build the graph
  for (let i = 0; i < words.length; i++) {
    for (let j = i + 1; j < words.length; j++) {
      if (findOverlap(words[i], words[j])) {
        graph[words[i]].push(words[j]);
        graph[words[j]].push(words[i]);
      }
    }
  }

  // Depth-First Search to check if all words are connected
  function dfs(word, visited) {
    visited.add(word);
    for (let neighbor of graph[word]) {
      if (!visited.has(neighbor)) {
        dfs(neighbor, visited);
      }
    }
  }

  const visited = new Set();
  dfs(words[0], visited);

  // If all words are visited, a crossword can be built
  return visited.size === words.length;
}
