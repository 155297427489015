import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Loading from '../../components/UI/Loading.js';
import CrosswordForm from '../../components/Crossword/CrosswordForm.js';
import WordItem from '../../components/Word/WordItem.js';
import WordBox from '../../components/Word/WordBox.js';
import {
  getCrossword,
  updateCrossword,
  updateWords,
  deleteCrossword,
} from '../../redux/actions/crosswords.js';

const EditCrossword = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdatingWords, setIsUpdatingWords] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const crosswordId = params.id;
  let crossword = props.crosswords.find(
    (crossword) => crossword && crossword.crosswordId === crosswordId
  );

  useEffect(() => {
    if (!crossword || !crossword.words) props.getCrossword(crosswordId);
  }, []);

  const handleCrosswordUpdate = (crossword) => {
    props.updateCrossword({
      crosswordId: crossword.crosswordId,
      data: { name: crossword.name, duration: crossword.duration },
    });
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  const handleCrosswordDelete = () => {
    props.deleteCrossword(crossword.crosswordId);
    navigate('/crosswords');
  };

  const handleUpdateWords = (words) => {
    props.updateWords({ crosswordId: crossword.crosswordId, words });
    setIsUpdatingWords(false);
  };

  const handleEditWordsCancel = () => {
    setIsUpdatingWords(false);
  };

  if (props.loading) return <Loading />;

  return (
    crossword && (
      <div className="quiz__container">
        {isEditing ? (
          <div className="quiz__header">
            <CrosswordForm
              crossword={crossword}
              onSubmit={handleCrosswordUpdate}
              onCancel={handleEditCancel}
            />
          </div>
        ) : (
          <div className="quiz__header">
            <div className="quiz__header__heading">
              <h3>{crossword.name}</h3>
              <p>{crossword.duration / 60} min.</p>
            </div>
            <div className="quiz__header__actions">
              <button
                className="btn--primary"
                onClick={() => {
                  setIsEditing(true);
                }}
                disabled={crossword.status === 'published'}
              >
                EDIT
              </button>
              <button
                className="btn--secondary"
                onClick={handleCrosswordDelete}
                disabled={crossword.status === 'published'}
              >
                DELETE
              </button>
            </div>
          </div>
        )}

        {crossword.words && crossword.words.length > 0 ? (
          isUpdatingWords ? (
            <WordBox
              words={crossword.words}
              onUpdate={handleUpdateWords}
              onCancel={handleEditWordsCancel}
            />
          ) : (
            <div className="word-item__container">
              <div className="word-item__content">
                <div className="word-item__answer">
                  <h4>ANSWER</h4>
                </div>
                <div className="word-item__clue">
                  <h4>CLUE</h4>
                </div>
              </div>
              {crossword.words.map((word, index) => (
                <WordItem key={index} word={word} />
              ))}
              {crossword.status === 'draft' && (
                <div className="word-item__controls">
                  <button
                    className="btn--primary"
                    onClick={() => setIsUpdatingWords(true)}
                  >
                    EDIT WORDS
                  </button>
                </div>
              )}
            </div>
          )
        ) : (
          <WordBox
            onUpdate={handleUpdateWords}
            onCancel={handleEditWordsCancel}
          />
        )}
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    crosswords: state.crosswords.crosswords,
    loading: state.crosswords.loading,
    error: state.crosswords.error,
    errorDetail: state.crosswords.errorDetail,
    editingWordId: state.crosswords.editingWordId,
  };
};

const mapDispatchToProps = {
  getCrossword,
  updateCrossword,
  updateWords,
  deleteCrossword,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCrossword);
