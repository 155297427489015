import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { getQuiz, getSubmissions } from '../../redux/actions/quizzes.js';
import Loading from '../../components/UI/Loading.js';

const ListSubmissions = (props) => {
  const params = useParams();
  const quizId = params.id;

  const quiz = props.quizzes.find((quiz) => quiz.quizId === quizId);

  useEffect(() => {
    if (!quiz) {
      props.getQuiz(quizId);
      props.getSubmissions(quizId);
    }
  }, []);

  if (props.loading) return <Loading />;

  return (
    <div className="submissions-container">
      {quiz && quiz.submissions && quiz.submissions.length > 0 ? (
        <>
          <div className="submission-column--name">
            <div className="submission--header">
              <p>Name</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.name}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--email">
            <div className="submission--header">
              <p>Email</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.email}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--score">
            <div className="submission--header">
              <p>Score</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.score}</p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p>No Submissions so far...</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    quizzes: state.quizzes.quizzes,
    loading: state.quizzes.loading,
    error: state.quizzes.error,
  };
};

const mapDispatchToProps = {
  getQuiz,
  getSubmissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSubmissions);
