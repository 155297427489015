import { createAsyncThunk } from '@reduxjs/toolkit';

import quizService from '../../services/quiz.service.js';

export const getQuizzes = createAsyncThunk(
  'quizzes/get',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const quizzes = await quizService.getQuizzes();
      return fulfillWithValue({ quizzes: [...quizzes] });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createQuiz = createAsyncThunk(
  'quizzes/create',
  async (quiz, { fulfillWithValue, rejectWithValue }) => {
    try {
      const createdQuiz = await quizService.createQuiz(quiz);
      return fulfillWithValue({ quiz: { ...createdQuiz } });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getQuiz = createAsyncThunk(
  'quizzes/getOne',
  async (quizId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const quiz = await quizService.getQuizDetails(quizId);
      return fulfillWithValue({ quiz });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateQuiz = createAsyncThunk(
  'quizzes/update',
  async ({ quizId, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const quiz = await quizService.updateQuiz(quizId, data);
      return fulfillWithValue(quiz);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteQuiz = createAsyncThunk(
  'quizzes/delete',
  async (quizId, { fulfillWithValue, rejectWithValue }) => {
    try {
      await quizService.deleteQuiz(quizId);
      return fulfillWithValue({ quizId });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateQuizStatus = createAsyncThunk(
  'quizzes/publish',
  async ({ quizId, status }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const quiz = await quizService.updateQuizStatus(quizId, status);
      return fulfillWithValue(quiz);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addQuestion = createAsyncThunk(
  'quizzes/addQuestion',
  async ({ quizId, question }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await quizService.addQuestion(quizId, question);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  'quizzes/updateQuestion',
  async ({ quizId, question }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await quizService.updateQuestion(quizId, question);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeQuestion = createAsyncThunk(
  'quizzes/removeQuestion',
  async ({ quizId, questionId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const question = await quizService.removeQuestion(quizId, questionId);
      return fulfillWithValue(question);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSubmissions = createAsyncThunk(
  'quizzes/getSubmissions',
  async (quizId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const submissions = await quizService.getSubmissions(quizId);
      return fulfillWithValue({ quizId, submissions });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
