import React from 'react';
import { useState } from 'react';

const QuizForm = (props) => {
  const [validationMessage, setValidationMessage] = useState('');
  const [quiz, setQuiz] = useState(
    props.quiz || {
      name: '',
      duration: '600',
    }
  );

  const handleNameChange = (e) => {
    if (e.target.value.length <= 35) {
      setQuiz({
        ...quiz,
        name: e.target.value,
      });
    } else {
      e.target.value = e.target.value.slice(0, 35);
    }
  };

  const handleDurationChange = (e) => {
    setQuiz({
      ...quiz,
      duration: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (quiz.name === '') {
      setValidationMessage('Quiz Name cannot be empty');
    } else {
      props.onSubmit(quiz);
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__control">
        <div>
          <input
            type="text"
            name="quizName"
            placeholder="Quiz Name"
            defaultValue={quiz.name}
            onChange={handleNameChange}
            onFocus={() => {
              setValidationMessage('');
            }}
          />
          <label className="character-limit">{quiz.name.length} / 35</label>
        </div>
        <div>
          Duration:{' '}
          {quiz.duration < 600 ? `0${quiz.duration / 60}` : quiz.duration / 60}{' '}
          min
          <input
            type="range"
            min={'300'}
            max={'3600'}
            step={300}
            defaultValue={quiz.duration}
            onChange={handleDurationChange}
          />
        </div>
      </div>
      <div className="form__actions">
        <button className="btn--primary" type="submit">
          {props.quiz ? 'UPDATE' : 'CREATE'}
        </button>
        <button className="btn--secondary" onClick={props.onCancel}>
          CANCEL
        </button>
      </div>
      <p className="validation-message">{validationMessage}</p>
    </form>
  );
};

export default QuizForm;
