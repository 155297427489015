import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import QuizForm from '../Quiz/QuizForm.js';
import { setlastCreatedQuizId } from '../../redux/reducers/quizzes.js';
import { createQuiz } from '../../redux/actions/quizzes.js';
import CrosswordForm from '../Crossword/CrosswordForm.js';
import { setlastCreatedCrosswordId } from '../../redux/reducers/crosswords.js';
import { createCrossword } from '../../redux/actions/crosswords.js';
import loading from '../../redux/selectors/loading.js';
import Loading from '../UI/Loading.js';

const AddDetails = (props) => {
  const navigate = useNavigate();

  if (props.loading) {
    return <Loading />;
  }
  switch (props.type) {
    case 'quiz':
      if (props.lastCreatedQuizId) {
        return (
          <div className="centered">
            <button
              className="btn--primary"
              onClick={() => {
                const modal = document.getElementById('create-kreeda');
                modal.style.display = 'none';
                props.setKreeda({
                  type: '',
                  details: {
                    name: '',
                    duration: '',
                  },
                  settings: {},
                });
                props.setCurrentStep(0);
                const quiId = props.lastCreatedQuizId;
                props.setlastCreatedQuizId({ quizId: null });
                navigate(`/quizzes/${quiId}`);
              }}
            >
              FINISH
            </button>
          </div>
        );
      }

      return (
        <QuizForm
          onSubmit={(quiz) => {
            props.createQuiz(quiz);
          }}
          onCancel={() => {
            props.setKreeda({
              type: '',
              details: {
                name: '',
                duration: '',
              },
              settings: {},
            });
            props.setCurrentStep(0);
          }}
        />
      );

    case 'crossword':
      if (props.lastCreatedCrosswordId) {
        return (
          <div className="centered">
            <button
              className="btn--primary"
              onClick={() => {
                const modal = document.getElementById('create-kreeda');
                modal.style.display = 'none';
                props.setKreeda({
                  type: '',
                  details: {
                    name: '',
                    duration: '',
                  },
                  settings: {},
                });
                props.setCurrentStep(0);
                const crosswordId = props.lastCreatedCrosswordId;
                props.setlastCreatedCrosswordId({ crosswordId: null });
                navigate(`/crosswords/${crosswordId}`);
              }}
            >
              FINISH
            </button>
          </div>
        );
      }

      return (
        <CrosswordForm
          onSubmit={(quiz) => {
            props.createCrossword(quiz);
          }}
          onCancel={() => {
            props.setKreeda({
              type: '',
              details: {
                name: '',
                duration: '',
              },
              settings: {},
            });
            props.setCurrentStep(0);
          }}
        />
      );
  }
  return <div>AddDetails</div>;
};

const mapStateToProps = (state) => {
  return {
    loading: loading(state.quizzes.loading, state.crosswords.loading),
    error: state.quizzes.error,
    errorDetail: state.quizzes.errorDetail,
    lastCreatedQuizId: state.quizzes.lastCreatedQuizId,
    lastCreatedCrosswordId: state.crosswords.lastCreatedCrosswordId,
  };
};

const mapDispatchToProps = {
  createQuiz,
  setlastCreatedQuizId,
  createCrossword,
  setlastCreatedCrosswordId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDetails);
